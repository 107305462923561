import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import Quiz from "./Quiz";
import { fetchQuizFromAPI, PumpQuiz } from "./api";

interface TildaProfile {
    name: string;
    login: string;
    lang: string;
    projectid: string;
}

const App: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [quiz, setQuiz] = useState<PumpQuiz | null>(null);
    const [user, setUser] = useState<string | null>(null);

    const searchParams = new URLSearchParams(window.location.search);
    const repoName = searchParams.get("repo_name") || "dbt-Pumping";
    const quizName = searchParams.get("quiz_name") || "1_sql_refactoring";

    useEffect(() => {
        let isMounted = true; // Track mounted state
        const loadQuiz = async () => {
            try {
                setLoading(true);
                setError(null);
                const quiz = await fetchQuizFromAPI(repoName, quizName);

                if (isMounted) {
                    // Only update state if still mounted
                    setQuiz(quiz);
                }
            } catch (error) {
                console.error("Error loading task:", error);
                if (isMounted) {
                    setError("Не удалось загрузить квиз. Попробуйте позже.");
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        if (quizName && repoName) {
            loadQuiz();
        }

        const handleMessage = (event: MessageEvent) => {
            // Verify the origin of the message
            if (event.origin !== "https://inzhenerka.tech") {
                console.log("Unsupported origin:", event.origin);
                return;
            }
            // Check if the message is the response from the parent window
            if (
                event.data &&
                event.data.type === "getTildaProfile"
            ) {
                const profile: TildaProfile = event.data.data;
                setUser(profile.login);
            }
        };
        // Add event listener for messages from the parent window
        window.addEventListener("message", handleMessage);
        // Send a message to the parent window to request the data
        window.parent.postMessage({ type: "getTildaProfile" }, "*");

        return () => {
            isMounted = false;
            window.removeEventListener('message', handleMessage);
        };
    }, [quizName, repoName]);

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            {quiz ? (
                <Quiz questions={quiz.questions} />
            ) : (
                <Typography>Квиз не найден</Typography>
            )}
        </Box>
    );
};

export default App;
