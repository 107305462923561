import axios from 'axios';

const API_BASE_URL: string = process.env.REACT_APP_API_BASE_URL || 'https://pump-room-api.inzhenerka-cloud.com';


type QuizType = 'single'

export interface PumpQuizQuestion {
    question: string;
    options: string[];
    correct_option_num: number;
    explanation?: string;
};

export interface PumpQuiz {
    name: string;
    repository: string;
    quiz_type: QuizType;
    questions: PumpQuizQuestion[];
};



export const fetchQuizFromAPI = async (repoName: string, quizName: string): Promise<PumpQuiz> => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get_quiz`, {
            params: { repo_name: repoName, quiz_name: quizName },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching quiz from API:', error);
        throw error;
    }
};
