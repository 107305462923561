import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    Chip,
} from "@mui/material";
import { PumpQuizQuestion } from "./api";

interface QuizProps {
    questions: PumpQuizQuestion[];
}

const OptionButton = styled(Button)(({ theme }) => ({
    fontSize: "1.15rem", // Increase font size
    lineHeight: "1.2",
    backgroundColor: "#fff", // Initial background color white
    textAlign: "left", // Align text to the left
    justifyContent: "flex-start", // Align content to the left
    textTransform: "none",
    "&.correct": {
        backgroundColor: "#7bed9f", // Light green
    },
    "&.incorrect": {
        backgroundColor: "#ffb8b8", // Light red
    },
}));

const getEmojiForGrade = (grade: number) => {
    if (grade < 0.3) return "😖";
    if (grade < 0.7) return "🤷‍♂️";
    if (grade < 0.9) return "👏";
    return "🎖️";
};

const CardContentNoPadding = styled(CardContent)(`
    &:last-child {
      padding-bottom: 5px;
    }
  `);

const Quiz: React.FC<QuizProps> = ({ questions }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [showAnswer, setShowAnswer] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [correctAnswersCount, setCorrectAnswersCount] = useState(0);

    const handleOptionClick = (option: string, index: number) => {
        setSelectedOption(option);
        setShowAnswer(true);
        if (index === questions[currentQuestionIndex].correct_option_num - 1) {
            setCorrectAnswersCount((prevCount) => prevCount + 1);
        }
    };

    const handleNextQuestion = () => {
        if (currentQuestionIndex === questions.length - 1) {
            setShowResults(true);
        } else {
            setShowAnswer(false);
            setSelectedOption(null);
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        }
    };

    const currentQuestion = questions[currentQuestionIndex];

    if (showResults) {
        const grade = correctAnswersCount / questions.length;
        const emoji = getEmojiForGrade(grade);

        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                // minHeight="100vh"
            >
                <Card
                    sx={{
                        minWidth: 350,
                        minHeight: 300,
                    }}
                >
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Результаты
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Правильных ответов: {correctAnswersCount}/
                            {questions.length}
                        </Typography>
                        <Box display="flex" justifyContent="center" mt={3}>
                            <Typography variant="h2" gutterBottom>
                                {emoji}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            sx={{ marginTop: 2 }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setShowResults(false);
                                    setCurrentQuestionIndex(0);
                                    setCorrectAnswersCount(0);
                                    setSelectedOption(null);
                                    setShowAnswer(false);
                                }}
                            >
                                Еще раз
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        );
    }

    return (
        <Box>
            <Card
                sx={{
                    minWidth: 350,
                    maxWidth: 600,
                    paddingLeft: 2,
                    height: 430,
                    overflow: "auto",
                }}
            >
                <CardContentNoPadding>
                    <Chip
                        label={`Вопрос ${currentQuestionIndex + 1}/${
                            questions.length
                        }`}
                        sx={{ marginBottom: 2 }}
                    ></Chip>
                    <Typography variant="h6" gutterBottom>
                        {currentQuestion.question}
                    </Typography>
                    <Box display="flex" flexDirection="column" mt={2}>
                        {currentQuestion.options.map((option, index) => {
                            const isCorrect =
                                showAnswer &&
                                index ===
                                    currentQuestion.correct_option_num - 1;
                            const isIncorrect =
                                showAnswer &&
                                selectedOption === option &&
                                index !==
                                    currentQuestion.correct_option_num - 1;
                            return (
                                <OptionButton
                                    key={option}
                                    color="secondary"
                                    className={
                                        isCorrect
                                            ? "correct"
                                            : isIncorrect
                                            ? "incorrect"
                                            : ""
                                    }
                                    onClick={() =>
                                        handleOptionClick(option, index)
                                    }
                                    disabled={showAnswer}
                                    sx={{ minHeight: 40 }}
                                >
                                    {option}
                                </OptionButton>
                            );
                        })}
                    </Box>
                    {showAnswer &&
                        selectedOption !==
                            currentQuestion.options[
                                currentQuestion.correct_option_num - 1
                            ] &&
                        currentQuestion.explanation && (
                            <Box
                                sx={{ mt: 2, overflow: "auto", maxHeight: 75 }}
                            >
                                <Typography color="textSecondary" gutterBottom>
                                    {currentQuestion.explanation}
                                </Typography>
                            </Box>
                        )}
                    {showAnswer && (
                        <Box display="flex" justifyContent="center" mt={1}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleNextQuestion}
                            >
                                {currentQuestionIndex === questions.length - 1
                                    ? "Результаты"
                                    : "Дальше"}
                            </Button>
                        </Box>
                    )}
                </CardContentNoPadding>
            </Card>
        </Box>
    );
};

export default Quiz;
